<template>
  <v-app-bar dense flat height="49" class="app-bar">
    <div style="width: 100%" class="d-flex justify-space-between">
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

      <v-toolbar-title class="text-uppercase hide-on-smaller" color="black" @click="navigateToHome" style="cursor: pointer">
        <span class="font-weight-light">BLACKDIAMOND</span>
        <span class="font-weight-bold ml-2">AGENCY</span>
      </v-toolbar-title>

      <h4 class="text-uppercase text-center pa-1" color="black">{{ $store.getters.getHeading }}</h4>

      <v-menu offset-y bottom dense>
        <template v-slot:activator="{ on, attrs }">
          <div class="appbar-user-email d-flex flex-row justify-space-between align-center" v-bind="attrs" v-on="on">
            {{ $store.getters.getUser.email }}
            <v-icon class="pl-2" small color="black">mdi-cog</v-icon>
          </div>
        </template>

        <v-list class="rounded-0 app-bar-menu-list" dense>
          <v-list-item v-for="link in links" :key="link.text" router :to="link.route">
            <v-list-item-icon>
              <v-icon color="#001536">{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      links: [
        { text: "Settings", icon: "mdi-hammer-screwdriver", route: "/dashboard/users/manageaccount/changesettings" },
        { text: "Logout", icon: "mdi-logout", route: "/account/logout" },
      ],
      notifications: [],
      notifications_routes: {
        users: "ChangeUserSettings",
        sitecheck: "statusCheck",
        sms: "Campaigns",
      },
      notifications_count: 0,
      notification_wait: 120000,
    };
  },
  computed: {
    notification_alert() {
      return this.notifications_count;
    },
  },
  created() {},
  async mounted() {
    // setInterval(this.getNotifications, this.notification_wait);
  },
  methods: {
    async getNotifications() {
      try {
        let res = await this.axios.get("/api/notifications/unreadusernotificationscount");
        if (!res.data.success) return;
        this.notifications_count = res.data.count;
        console.log(this.notifications_count);
      } catch (error) {
        console.log(error);
      }
    },
    navigateToHome() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style scoped>
.app-bar {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.app-appbar {
  background-color: var(--v-bstopbar-base) !important;
}
::v-deep .small-font,
::v-deep .v-label,
::v-deep .v-input {
  font-weight: 500;
  font-size: 13px !important;
}
.notification_title {
  color: black;
  font-weight: 500;
  font-size: 12px !important;
}
.notification_text {
  font-weight: 500;
  font-size: 12px !important;
  line-height: 1.5em;
}

::v-deep .notification_badge .v-badge__badge {
  font-size: 10px;
  height: fit-content;
  min-width: fit-content;
  padding: 2px 4px;
}
::v-deep .notification_badge .v-badge__badge::after {
  border-width: 1px;
}
::v-deep .notification_link {
  text-decoration: none;
}

.action-list-item {
  font-size: 13px !important;
  font-weight: 600;
  cursor: pointer;
  padding: 0px 24px;
}

.action-list-item .v-list-item__icon {
  font-size: 1.5em;
  margin-right: 10px;
}
.app-bar-menu-list .v-list-item__icon {
  margin-right: 16px !important;
}
.action-list-item .v-icon {
  color: #001536;
}
.action-list-item-title {
  font-size: 13px !important;
  cursor: pointer;
}

.appbar-user-email {
  color: black;
  font-size: 11px !important;
  font-weight: 600;
}

@media (max-width: 767px) {
  .hide-on-smaller {
    display: none;
  }
}
</style>
