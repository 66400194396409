<template>
  <v-row justify="center">
    <!-- Snackbar for small info alerts -->
    <v-snackbar @input="update_snackbar" :value="showSnackbar" timeout="1000" :vertical="true" app bottom right style="opacity: 1; z-index: 2005" content-class="main-snackbar-content" elevation="24">
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <template>
          <div>{{ snackbarText2 }}</div>
        </template>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <!-- main dialog box -->
    <v-dialog :value="show" persistent fullscreen hide-overlay transition="dialog-bottom-transition" class="rounded-0">
      <v-card>
        <v-toolbar dense dark color="primary" class="rounded-0 read-mode-toolbar">
          <v-spacer></v-spacer>
          <div>
            <div>Invoice</div>
          </div>
          <v-spacer></v-spacer>
          <div>
            <!-- <v-btn color="white" style="text-transform: capitalize" class="mr-2 black--text" @click="download_csv" small elevation="2" :disabled="!data || !data.length">
              <v-icon class="mr-1" small>mdi-cloud-download</v-icon>
              Download CSV
            </v-btn>

            <v-btn color="white" style="text-transform: capitalize" class="mr-2 black--text" @click="create_xero_invoice" small elevation="2" :disabled="!data || !data.length">
              <v-icon class="mr-1" small>mdi-check</v-icon>
              Create Xero Invoice
            </v-btn> -->

            <v-btn small icon dark class="ml-4" @click="$emit('close-invoice-dialog', false)">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
            <v-btn small icon dark class="ml-4" @click="to_csv_simple">
              <v-icon small>mdi-download</v-icon>
            </v-btn>
          </div>
        </v-toolbar>

        <div class="d-flex flex-row justify-center align-center small-font" style="width: 100%">
          <!-- <pre class="py-8 px-2" style="max-width: 700px" v-html="text"></pre> -->
          <!-- History section -->
          <v-row class="mt-0">
            <v-col cols="12">
              <v-sheet color="white" elevation="3" rounded class="ma-4 py-3">
                <template>
                  <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="items"
                    :options.sync="options"
                    item-key="id"
                    class="rounded-6 datatable-height"
                    :show-select="false"
                    :single-select="singleSelect"
                    :show-expand="false"
                    :single-expand="singleExpand"
                    :expanded.sync="expanded"
                    fixed-header
                    dense
                    :loading="table_loading"
                    elevation="10"
                    hide-default-footer
                  >
                    <!-- Actions slot -->

                    <!-- Date slot -->
                    <template v-slot:[`item.createdAt`]="{ item }">
                      {{ format_date(item.createdAt) }}
                    </template>

                    <!-- Description  slot -->
                    <template v-slot:[`item.desc`]="{ item }">
                      <div class="py-2">
                        <div v-if="item.role">{{ item.count }}x {{ item.role }}:</div>
                        <div v-if="item.date" v-html="format_invoice_date(item.date)"></div>
                        <div v-if="item.time">Time: {{ item.time.join("-").replace(/\s/g, "") }}</div>
                        <div v-if="item.desc">{{ item.desc }}</div>
                      </div>
                    </template>

                    <!-- Description  slot -->
                    <template v-slot:[`item.qty`]="{ item }">
                      <div class="py-2">
                        <div>{{ item.count ? item.count * item.qty : item.qty }}</div>
                      </div>
                    </template>
                  </v-data-table>
                </template>
              </v-sheet>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { parse } from "json2csv";
export default {
  props: ["show", "data"],
  data: () => {
    return {
      // snackbar fields
      showSnackbar: false,
      snackbarText: "",
      snackbarText2: "",

      // table fields
      headers: [
        { text: "Item", value: "item", align: "start", sortable: false, width: "100px" },
        { text: "Description", value: "desc", sortable: false, width: "400px" },
        { text: "Quantity", value: "qty", sortable: false, width: "100px" },
        { text: "Unit Price", value: "price", sortable: false, width: "100px" },
        { text: "GST", value: "gst", sortable: false, width: "100px" },
        { text: "Amount AUD", value: "amount", sortable: false, width: "200px" },
        // { text: "Actions", value: "actions", sortable: false },
      ],
      items: [],
      items_length: 0,
      options: {
        page: 1,
        itemsPerPage: -1,
        itemsLength: 0,
        sortBy: ["id"],
        sortDesc: [true],
      },
      // table expansion
      expanded: [],
      singleExpand: false,

      // Table selection
      singleSelect: false,
      selected: [],
      table_loading: false,

      csv_fields: ["item", "desc", "qty", "price", "gst", "amount", "count", "date", "dates", "gst_amount", "role", "time"],
      jsonData: [
        { name: "John", age: 30, city: "New York" },
        { name: "Jane", age: 25, city: "Paris" },
        { name: "Peter", age: 35, city: "Berlin" },
      ],
    };
  },
  watch: {
    data: {
      handler(newval) {
        this.items = newval;
        this.items_length = newval.length;
      },
      deep: true,
    },
  },
  methods: {
    //   copy results to clipboard
    async copyResult() {
      try {
        if (!this.text) return;
        await navigator.clipboard.writeText(this.text);
        this.snackbarShow(true, "Text Copied", "");
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", "Text could not be copied to clipboard, kindly try doing it manually");
      }
    },
    // show/unshow snakbar => mostly errors
    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },
    update_snackbar(v) {
      this.showSnackbar = v;
    },
    download_csv() {
      console.log("Downloading CSV");
    },
    create_xero_invoice() {
      try {
        console.log("Sending to Xero");
        this.axios.post("/api/pricing/createxeroinvoice", { data: this.data });
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Data could not be send to Xero - Check your connection");
      }
    },
    format_invoice_date(date_string) {
      date_string = date_string.replace(/,\s/g, " <br>");
      date_string = "Dates: <br>" + date_string;
      console.log(date_string);
      return date_string;
    },

    to_csv_simple() {
      // prepare object
      `<div v-if="item.role">{{ item.count }}x {{ item.role }}:</div>
      <div v-if="item.date" v-html="format_invoice_date(item.date)"></div>
      <div v-if="item.time">Time: {{ item.time.join("-").replace(/\s/g, "") }}</div>
      <div v-if="item.desc">{{ item.desc }}</div>`;

      const export_data = [];
      for (const i of this.items) {
        let desc = ``;
        if (i.role) desc += `${i.count}x ${i.role} \n`;
        if (i.date) desc += `Dates: ${i.date} \n`;
        if (i.time) desc += `Time: ${i.time.join("-").replace(/\s/g, "")} \n`;
        if (i.desc) desc += `${i.desc} \n`;

        export_data.push({
          Item: i.item,
          Description: desc,
          Quantity: i.qty,
          "Unit Price": i.price,
          GST: i.gst,
          "Amount AUD": i.amount,
        });
      }
      console.log(export_data);
      this.downloadCSV(export_data);
    },

    downloadCSV(export_data) {
      try {
        const csvContent = parse(export_data);
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "invoice.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        console.error("Error converting JSON to CSV:", err);
      }
    },
  },
};
</script>

<style scoped>
.read-mode-toolbar {
  height: 50px;
}
.result_div {
  line-height: 1.5em;
}
::v-deep .result_div span {
  line-height: 1.5em;
  background-color: yellow !important;
}

.small-font,
.small-font * {
  font-weight: 500;
  font-size: 13px !important;
}

::v-deep th {
  font-weight: bold;
  font-size: 13px !important;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}
</style>
