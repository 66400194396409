<template>
  <!-- View Layout -->
  <v-row class="d-flex flex-row justify-space-between align-start ma-0 pa-0" align="stretch">
    <!-- View header -->
    <text-view-dialog :show="show_read_dialog" :text="result" @close-read-dialog="(v) => (show_read_dialog = v)"></text-view-dialog>
    <invoice-view-dialog :show="show_invoice_dialog" :data="combined_result" @close-invoice-dialog="(v) => (show_invoice_dialog = v)"></invoice-view-dialog>
    <job-parser :show="show_job_parser" @job-parser-toggled="(v) => (show_job_parser = v)" @show-snack-bar="(v) => snackbarShow(...v)" @jobs-updated="update_jobs"></job-parser>

    <v-overlay :value="loading" style="z-index: 20001" :opacity="0.9">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <div class="py-2 d-flex flex-column align-center justify-center">
          <v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular>
          <!-- <span class="pa-2 text-center" style="line-height: 2em" v-html="loading_quote"></span> -->
        </div>
        <div class="py-2 d-flex align-baseline justify-center">Processing</div>
      </v-snackbar>
    </v-overlay>

    <!-- Snackbar for small info alerts -->
    <v-snackbar
      @input="(v) => (showSnackbar = v)"
      :value="showSnackbar"
      timeout="3000"
      :vertical="true"
      app
      top
      right
      style="opacity: 1; z-index: 2005"
      content-class="main-snackbar-content"
      elevation="24"
    >
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <div>{{ snackbarText2 }}</div>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <!-- Input Column -->
    <v-col cols="12" class="pa-0 d-flex">
      <v-sheet color="white" elevation="3" rounded class="mr-md-1 pb-3 mx-0 column-content w-100">
        <!-- Form section -->
        <v-row class="pr-5 pl-2 mt-6 pb-0 align-baseline justify-center" no-gutters>
          <v-col cols="12" md="12">
            <v-row>
              <!-- job details column / tabs -->
              <v-col cols="12" md="8" class="mr-0 pr-0 py-0 d-flex flex-column align-baseline justify-start">
                <v-sheet color="bsprimary2" elevation="3" rounded class="pa-4 mb-2" width="100%" height="100%">
                  <div class="d-flex flex-row align-baseline justify-space-between w-100 ma-0 pa-0">
                    <!-- <h5 class="mb-4 text-left">Job Details:</h5> -->
                    <h5 class="mb-4 w-100 d-flex flex-row align-baseline justify-space-between">
                      <span>Job Details:</span>
                      <div>
                        <v-btn color="white" style="text-transform: capitalize" class="mr-2" @click="show_job_parser = true" small elevation="2">
                          <v-icon class="mr-1" small>mdi-robot</v-icon>
                          Show AI Job Parser
                        </v-btn>

                        <v-btn color="white" style="text-transform: capitalize" class="mr-1" @click="remove_job()" small elevation="2">
                          <v-icon small>mdi-close-box-outline</v-icon>
                          Remove Job
                        </v-btn>
                        <v-btn color="white" style="text-transform: capitalize" @click="add_job()" small elevation="2">
                          <v-icon small>mdi-plus-box-outline</v-icon>
                          Add Job
                        </v-btn>
                      </div>
                    </h5>
                  </div>

                  <!-- tab headings -->
                  <v-tabs background-color="bssecondary2" color="white" class="tabs-main" show-arrows v-model="tab" left slider-color="yellow">
                    <v-tabs-slider color="warning"></v-tabs-slider>
                    <v-tab color="white" v-for="(j, i) of jobs" :key="'tab-' + i" :href="'#j' + i" @click="current_tab_index = i">Job{{ i + 1 }} </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tab" class="chatbot-settings-wrapper">
                    <v-tab-item v-for="(j, i) of jobs" :key="'tab-item-' + i" :value="'j' + i">
                      <div class="d-flex flex-column justify-center align-start pa-4" style="max-width: 600px">
                        <!-- components -->
                        <staff-role-selector :roles="j.staff" @staff-roles-updated="(v) => (j.staff = v)"></staff-role-selector>
                        <time-range-picker :ranges="j.dt_ranges" @time-updated="(v) => (j.dt_ranges = v)"></time-range-picker>
                        <location-picker :place="j.place" @place-selection-updated="(v) => (j.place = v)"></location-picker>
                        <form-generator :fields="j.fields"></form-generator>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </v-sheet>
              </v-col>
              <!-- <v-spacer></v-spacer> -->
              <!-- client details column -->
              <v-col cols="12" md="4" class="mr-0 pr-0 py-0 settings-div">
                <v-sheet color="bsprimary2" elevation="3" rounded class="pa-4 mb-2">
                  <div class="w-100 pt-0 d-flex flex-row align-baseline justify-space-between">
                    <h5 class="mb-4 text-left">Client Details</h5>
                  </div>

                  <!-- components -->
                  <form-generator :fields="setting_fields"></form-generator>

                  <div class="w-100 pt-4 d-flex flex-row flex-wrap align-baseline justify-end">
                    <v-btn block class="my-1 ml-1 success-btns black--text" @click="submit_request()" small elevation="2">
                      <v-icon class="mr-1" small>mdi-check</v-icon>
                      Calculate
                    </v-btn>

                    <v-btn block class="my-1 ml-1 success-btns black--text" @click="show_invoice_dialog = true" small elevation="2" :disabled="!combined_result.length">
                      <v-icon class="mr-1" small>mdi-file-check</v-icon>
                      Show Invoice
                    </v-btn>

                    <v-btn block class="my-1 danger-btns black--text" @click="reset_form()" small elevation="2">
                      <v-icon class="mr-1" small>mdi-reload</v-icon>
                      <span>Reset Jobs</span>
                    </v-btn>
                  </div>
                </v-sheet>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- History section -->
        <v-row class="mt-0">
          <v-col cols="12">
            <v-sheet color="white" elevation="3" rounded class="mx-2 mt-3">
              <template>
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="history"
                  :options.sync="options"
                  :server-items-length="history_length"
                  item-key="id"
                  class="rounded-6 datatable-height"
                  :show-select="false"
                  :single-select="singleSelect"
                  :show-expand="false"
                  :single-expand="singleExpand"
                  :expanded.sync="expanded"
                  fixed-header
                  dense
                  :footer-props="{ 'items-per-page-options': [10, 20, 30, 50] }"
                  :loading="table_loading"
                  elevation="10"
                >
                  <!-- Actions slot -->
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip color="bssecondary2" left :open-delay="50">
                      <template v-slot:activator="{ on }">
                        <v-icon color="bsdanger" small class="mr-2" @click="remove_single_history(item.id)" v-on="on">mdi-trash-can</v-icon>
                      </template>
                      <span>Remove From History</span>
                    </v-tooltip>
                    <v-tooltip color="bssecondary2" left :open-delay="50">
                      <template v-slot:activator="{ on }">
                        <v-icon color="bd3" small class="mr-2" @click="load_single_history(item)" v-on="on">mdi-arrow-up-circle</v-icon>
                      </template>
                      <span>Load History</span>
                    </v-tooltip>
                  </template>

                  <!-- Date slot -->
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ format_date(item.createdAt) }}
                  </template>

                  <template v-slot:top>
                    <!-- History row -->
                    <v-row class="px-4 py-1 align-baseline justify-center" no-gutters>
                      <v-col cols="12" md="12" class="mx-0 px-0mr-0 py-0">
                        <h4 class="my-2 mx-0 px-0 text-left pr-2 w-100 d-flex flex-row align-baseline justify-space-between">
                          <span>History</span>
                          <v-btn color="white" style="text-transform: capitalize" @click="loadHistory()" small elevation="2">
                            <v-icon class="mr-1" small>mdi-reload</v-icon>
                            Refresh
                          </v-btn>
                        </h4>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </template>
            </v-sheet>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import InvoiceViewDialog from "./InvoiceViewDialog.vue";
import TextViewDialog from "./TextViewDialog.vue";
import TimeRangePicker from "./TimeRangePicker.vue";
import FormGenerator from "./FormGenerator.vue";
import LocationPicker from "./LocationPicker.vue";
import StaffRoleSelector from "./StaffRoleSelector.vue";
import JobParser from "./JobParser.vue";
import moment from "moment";
const { user_fields, system_fields, cities } = require("./data");
const fns = require("./functions");
import _ from "lodash";

export default {
  name: "PricingEngineCalculator",
  components: {
    TextViewDialog,
    TimeRangePicker,
    FormGenerator,
    LocationPicker,
    StaffRoleSelector,
    InvoiceViewDialog,
    JobParser,
  },
  data: () => {
    return {
      jobs: [],
      dt_ranges: [],
      place: null,
      staff: [],
      fields: user_fields,
      setting_fields: system_fields,

      result: "",

      // new fields
      headers: [
        { text: "Client", value: "client.name", align: "start", sortable: true },
        { text: "Award", value: "award", sortable: true },
        { text: "Tier", value: "tier", sortable: true },
        { text: "Total", value: "total", sortable: true },
        { text: "Created", value: "createdAt", sortable: true },
        { text: "Actions", value: "actions", sortable: false },
      ],
      history: [],
      history_length: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        itemsLength: 0,
        sortBy: ["id"],
        sortDesc: [true],
      },
      requestSent: false,

      // snackbar fields
      showSnackbar: false,
      snackbarText: "",
      snackbarText2: "",

      // table expansion
      expanded: [],
      singleExpand: false,

      // Table selection
      singleSelect: false,
      selected: [],
      table_loading: false,

      cresult: "",
      result_editable: true,

      show_read_dialog: false,

      // invoice component fields
      show_invoice_dialog: false,
      combined_result: [],

      // tab fields
      tab: "",
      current_tab_index: 0,
      unique_id: 1,

      // job parser fields
      show_job_parser: false,
    };
  },
  computed: {
    loading() {
      return this.requestSent;
    },
  },
  watch: {
    result(newval) {
      this.cresult = newval;
    },
    // new fields
    options() {
      // this.loadHistory();
    },

    dt_ranges(newval) {
      // console.log("Value changed in parent", newval);
    },
    staff: {
      deep: true,
      handler(newval) {
        // console.log("Staff Updated (parent)", newval);
      },
    },
    // fields: {
    //   deep: true,
    //   handler(newval) {
    //     console.log("Vales changed in parent: ", newval);
    //   },
    // },
    jobs: {
      deep: true,
      handler(newval) {
        // console.log("Job Updated", newval);
      },
    },
  },
  async mounted() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.add_job();
        await this.loadHistory();
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Error while initializing page");
      }
    },
    add_job() {
      try {
        const job = {
          id: this.unique_id++,
          dt_ranges: [],
          place: null,
          staff: [],
          fields: _.cloneDeep(this.fields),
        };
        this.jobs.push(job);
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Error while initializing a job");
      }
    },
    remove_job() {
      try {
        if (this.jobs.length > 1) this.jobs.splice(-1, 1);
        // if (this.jobs.length > 1 && this.current_tab_index != null) this.jobs.splice(this.current_tab_index, 1);
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Error while removing a job");
      }
    },
    async submit_request() {
      try {
        if (this.requestSent) return;
        this.requestSent = true;
        await new Promise((r) => setTimeout(r, 300)); // adding a delay for combobox to set its value on loosing focus

        // validating setting fields

        if (!this.setting_fields.tier.value) throw new Error("Select a Tier to calculate cost");
        if (!this.setting_fields.client_name.value) throw new Error("Client name is required for record purposes");
        if (this.setting_fields.new_client.value == false && !this.setting_fields.client_id.value) throw new Error("Invalid client id, try selecting the client again");

        const jobs = _.cloneDeep(this.jobs);

        // validating job fileds for each job
        for (const job of jobs) {
          console.log(job);

          // validating location
          if (!job.place?.position) throw new Error(`Job${job.id}: Select a location for the job`);

          // validating datetime ranges

          // validating staff
          if (!job.staff || !job.staff.length) throw new Error(`Job${job.id}: Atleast one staff type should be selected per job`);
          for (const s of job.staff) if (!s.role || !s.count) throw new Error(`Job${job.id}: Staff role & number must be selected`);

          // validating other input fields
          const fields = {};
          for (const [key, o] of Object.entries(this.setting_fields)) job[key] = o.value;
          for (const [key, o] of Object.entries(job.fields)) job[key] = o.value;

          // validating award
          if (!job.award) throw new Error(`Job${job.id}: Select an award for the job`);

          // setting variables
          job.place = {
            formatted_address: job.place.formatted_address,
            place_id: job.place.place_id,
            types: job.place.types,
            vicinity: job.place.vicinity,
            position: job.place.position,
            city: job.place.city,
            state: job.place.state,
          };
          delete job.fields;
        }

        const res = await this.axios.post(`/api/pricing/calculate`, { jobs });
        // console.log(res.data);
        this.requestSent = false;

        if (!res.data.success) throw new Error(res.data.message);

        this.combined_result = res.data.combined_invoice;
        this.show_invoice_dialog = true;
        // console.log(this.combined_result);

        // await this.loadHistory();
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Response could not be generated");
      }
    },

    // setting defaults in data fields
    set_default_data_fields() {
      this.set_default_fields(this.fields);
    },
    // setting defaults for setting fields
    set_default_setting_fields() {
      this.set_default_fields(this.setting_fields);
    },

    get_filtered_fields(fields) {
      const data = {};
      try {
        for (const [k, f] of Object.entries(fields)) {
          if (!f.show) continue;

          if (Array.isArray(f.value) && !f.value.length) continue;
          else if (typeof f.value == "object" && !Object.keys(f.value).length) continue;
          else if (typeof f.value == "boolean" && [null, undefined].includes(f.value)) continue;
          else if (typeof f.value == "integer" && f.value < 0) continue;
          else if (typeof f.value == "string" && !f.value) continue;
          else {
            data[k] = { value: f.value, input: f.input, include: f.include };
          }
        }
        return data;
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Could not filter fields, try refreshing the page and starting again");
        return data;
      }
    },

    // main default setting funciton
    set_default_fields(fields) {
      try {
        for (const [k, f] of Object.entries(fields)) {
          if (Array.isArray(f.input_data.default)) {
            f.value = Object.assign([], f.input_data.default);
          } else if (typeof f.input_data.default == "object") f.value = Object.assign({}, f.input_data.default);
          else f.value = f.input_data.default;

          if (![null, undefined].includes(f.includes)) f.include = true;
        }
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Defaults could not be set, there might be an issue with data types");
      }
    },

    setDefaults() {
      this.set_default_data_fields();
      this.set_default_setting_fields();
      this.result = "";
      this.excluded = [];
      this.stock_photo_links = [];
    },

    reset_form() {
      this.show_invoice_dialog = false;
      this.combined_result = [];

      this.jobs = [];
      this.unique_id = 1;
      this.add_job();
      this.setting_fields = _.cloneDeep(system_fields);

      this.tab = "";
      this.current_tab_index = 0;
    },

    async copyResult() {
      try {
        if (!this.result) return;
        await navigator.clipboard.writeText(this.result);
        this.snackbarShow(true, "Text Copied", "");
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", "Text could not be copied to clipboard, kindly try doing it manually");
      }
    },
    async loadHistory() {
      try {
        // checking for other requests
        if (this.table_loading) return;
        this.table_loading = true;
        await new Promise((r) => setTimeout(r, 200));
        // sending request for history
        let res = await this.axios.post("/api/pricing/gethistory", { options: this.options });
        this.table_loading = false;

        if (!res.data.success) throw new Error(res.data.message);

        this.history = res.data.history;
        this.history_length = res.data.count;
      } catch (error) {
        console.log(error);
        this.table_loading = false;
        this.snackbarShow(true, "Error", "History could not be loaded: check your internet connection");
      }
    },

    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },

    async load_single_history(h) {
      try {
        this.table_loading = true;
        this.reset_form();
        this.jobs = [];
        for (let i = 0; i < h.input.length; i++) {
          this.add_job();
          const job = this.jobs[i];
          const input = h.input[i];

          // fields for individual components
          job.place = input.place;
          job.dt_ranges = input.dt_ranges;
          job.staff = input.staff;

          // setting fields created by form generator
          for (const [key, field] of Object.entries(job.fields)) field.value = input[key];
        }

        const first_job = h.input[0];
        for (const [key, field] of Object.entries(this.setting_fields)) field.value = first_job[key];

        this.combined_result = h.output.combined_invoice;
        this.show_invoice_dialog = false;

        this.table_loading = false;
      } catch (error) {
        console.log(error);
        this.table_loading = false;
      }
    },
    async remove_single_history(id) {
      try {
        this.table_loading = true;
        let res = await this.axios.post("/api/pricing/removehistory", { id });
        this.table_loading = false;
        if (!res.data.success) throw new Error(res.data.message);
        await this.loadHistory();
      } catch (error) {
        console.log(error);
        this.table_loading = false;
      }
    },

    format_date(date) {
      if (!date) return date;
      return moment(date).format("DD MMM YYYY (hh:mm:ss)");
    },
    downloadDoc() {
      let file_name = this.blog.theme ? this.blog.theme : "Blog";
      fns.download_doc(this.result, file_name);
    },

    async update_jobs(jobs) {
      try {
        this.table_loading = true;
        this.reset_form();
        this.jobs = [];
        const covered_cities = cities.map((c) => c.text.value);

        for (let i = 0; i < jobs.length; i++) {
          this.add_job();
          const job = this.jobs[i];
          const input = jobs[i];

          // fields for individual components
          job.place = input.place;
          job.dt_ranges = input.dt_ranges;
          job.staff = input.staff;

          // setting fields created by form generator
          for (const [key, field] of Object.entries(job.fields)) if (input[key] != null) field.value = input[key];

          // city field
          if (input.city) job.fields.city.value = covered_cities.includes(input.city.toLowerCase()) ? input.city.toLowerCase() : "other";
        }

        const first_job = jobs[0];
        for (const [key, field] of Object.entries(this.setting_fields)) if (first_job[key] != null) field.value = first_job[key];
        this.show_job_parser = false;

        this.table_loading = false;
      } catch (error) {
        console.log(error);
        this.table_loading = false;
      }
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu" !important;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
  color: black;
}
::v-deep .v-toolbar__content {
  padding-left: 0px;
  padding-right: 0px;
}
.v-data-table {
  border: 1px solid #4b414124;
}
::v-deep th.text-start {
  min-width: 100px;
}
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}

::v-deep .lc-input-fields {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
}

.settings-div .v-btn:not(.icon-only) {
  text-transform: initial;
  width: 146px;
  float: right;
}
.settings-div .v-btn .v-icon {
  width: 20px;
}
/* ::v-deep .v-input--selection-controls__ripple {

    margin: 0px !important;
} */
::v-deep .v-input__slider label {
  width: 75px;
}
.datatable-height {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.w-45 {
  width: 45% !important;
}
.h-100 {
  height: 100%;
}
.h-80 {
  height: 80%;
}
.highlighted {
  background-color: yellow;
}
.result_div {
  line-height: 1.5em;
}
::v-deep .result_div span {
  line-height: 1.5em;
  background-color: yellow !important;
}
pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  padding: 12px;
  line-height: 1.75rem;
  width: 100%;
  font: inherit;
  font-weight: 500;
  font-size: 13px !important;
}
.result_alert {
  font: inherit;
  font-weight: 500;
  font-size: 13px !important;
}
.sub-heading {
  font-size: 14px;
  font-weight: 500;
}

::v-deep .v-list-item__title {
  text-transform: capitalize;
}

::v-deep .v-select.v-input--dense .v-chip {
  margin: 1px 4px;
}

/* scroll styling */
#content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(77, 75, 75, 0.3);
  background-color: #f5f5f5;
}

#content::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

#content::-webkit-scrollbar-thumb {
  background-color: #8d8d8d;
  border: 1px solid #8d8d8d;
}

/* backgrounds */
::v-deep .v-data-table {
  background-color: var(--v-bsprimary2-base) !important;
}
::v-deep .v-data-table th {
  background-color: var(--v-bsprimary1-base) !important;
}

.column-content {
  flex: 1;
  align-self: stretch;
}

.v-tooltip__content {
  max-width: 500px;
  white-space: normal;
  word-wrap: break-word;
}

::v-deep .success-btns {
  background-color: var(--v-bd1-base) !important;
}
::v-deep .danger-btns {
  background-color: var(--v-bd2-base) !important;
}

.tooltip-text {
  color: black;
}
.chatbot-settings-wrapper .v-btn {
  text-transform: capitalize;
  min-width: 200px;
  float: right;
}

.tabs-main {
  border-radius: 3px;
  margin-bottom: 5px;
}
.v-tabs .v-tab {
  color: #ffffff !important; /* Navy color */
}
</style>
